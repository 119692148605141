import * as Sentry from '@sentry/react';
import { fullPath } from './base/Utils';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),

    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracePropagationTargets: ['localhost', /^https:\/\/legacyapi\.swonkie\.work/, /^https:\/\/legacyapi\.swonkie\.com/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.getCurrentScope().setTransactionName(fullPath());
